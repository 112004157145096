nav {
    background: rgba(0,0,0,0.3);
    width: max-content;
    display: block;
    padding: 0.7rem 1.5rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
    animation: move-left reverse 0.5s ease-in ;
}

@keyframes move-left {
    to{
        transform: translateX(-30px);
    }
}

nav a {
    background: transparent;
    padding: 0.7rem;
    border-radius: 50%;
    display: flex;
    /* color: var(--color-light); */
    font-size: 1rem;
    transition: var(--transition);
}

nav a:hover {
    color: var(--color-white);
    background: rgba(0,0,0,0.3);
    transform: scale(1.3);
}

