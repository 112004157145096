header {
  height: 100vh;
  padding-top: 7rem;
  overflow: visible;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* ********CTA******** */
.cta {
  margin-top: 2.5rem;
  margin-bottom: 3rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

.cv-btn {
  animation: move-right forwards 0.7s ease-in;
  transition: var(--transition);
}

.contact-btn {
  animation: move-left reverse 0.7s ease-in;
}

/* ********Header Socials******** */
.header__socials {
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
  animation: move-down reverse 0.7s ease-in;
  transition: var(--transition);
}

.header__socials a:hover {
  transform: scale(1.3);
}

.header__socials::after {
  content: '';
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* ********Me******** */
.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: 20rem;
  height: auto;
  position: absolute;
  left: calc(50% - 11rem);
  border-radius: 74% 24% 25% 73% / 46% 32% 66% 52%;
  overflow: hidden;
  padding: 5rem 1.5rem 1.5rem 1.5rem;
  box-shadow: -3px -3px 2px rgba(41, 41, 41, 0.3),
    5px 5px 5px rgba(0, 0, 0, 0.2), 15px 15px 15px rgba(0, 0, 0, 0.2);
}

.me > img {
  width: 90%;
  height: auto;
  animation: move-down reverse 0.7s ease-in;
  transition: var(--transition);
}

.me > img:hover {
  transform: rotate(10deg) scale(1.2);
}

/* ********Scroll down******** */
.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 1rem;
  animation: move-down reverse 0.7s ease-in;
}

@keyframes move-down {
  to {
    transform: translateY(-40px);
  }
}

@keyframes move-right {
  to {
    transform: translateX(-20px);
  }
}

@keyframes move-left {
  to {
    transform: translateX(-20px);
  }
}

/* MEDIA QUERIES MEDIUM DEVICES */
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}

/* MEDIA QUERIES SMALL DEVICES */
@media screen and (max-width: 600px) {
  header {
    height: 90vh;
  }

  .me {
    left: 15%;
    width: 60%;
    height: auto;
  }
}
