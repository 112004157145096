.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    box-shadow: -3px -3px 2px rgba(41, 41, 41, 0.3),
    5px 5px 5px rgba(0, 0, 0, 0.2), 15px 15px 15px rgba(0, 0, 0, 0.2);
}

.portfolio__item:hover {
    border-color: var(--color-bg-variant);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
    
}

.portfolio__item h3 {
    margin-top: 1rem;
}

.portfolio__item p {
    color: var(--color-light);
    margin: 0 0 2rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

/* MEDIA QUERIES MEDIUM DEVICES */
@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.2rem;
    }
}

/* MEDIA QUERIES SMALL DEVICES */
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}