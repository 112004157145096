.container.contact__container {
  width: 60%;
  display: grid;
  grid-template-columns: 30% 58%;
  gap: 10%;
}

.contact__options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 200px;
}

.contact__options h5 {
  margin-left: -10px;
}

.contact__option {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
  box-shadow: -3px -3px 2px rgba(41, 41, 41, 0.3),
    5px 5px 5px rgba(0, 0, 0, 0.2), 15px 15px 15px rgba(0, 0, 0, 0.2);
}

.contact__option:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  transform: scale(1.1);
}

.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
}

/* ******FORM********* */
.form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input {
  /* color: var(--color-white); */
  margin-bottom: 1rem;
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  /* background: transparent; */
  /* border: 2px solid var(--color-primary-variant); */
  resize: none;
  box-shadow: -3px -3px 2px rgba(41, 41, 41, 0.3),
    5px 5px 5px rgba(0, 0, 0, 0.2), 15px 15px 15px rgba(0, 0, 0, 0.2);
}

/* MEDIA QUERIES MEDIUM DEVICES */
@media screen and (max-width: 1024px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

/* MEDIA QUERIES SMALL DEVICES */
@media screen and (max-width: 600px) {
  .container.contact__container {
    width: var(--container-width-sm);
  }
}
